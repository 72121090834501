<template>
  <div>
    <v-overlay :value="overlay">
     <v-progress-circular
       indeterminate
       size="64"
     ></v-progress-circular>
    </v-overlay>
    <div class="d-flex">
      <v-avatar color="grey lighten-1">
        <v-icon dark>
          mdi-account-circle
        </v-icon>
      </v-avatar>
      <h1 class="ml-2">{{ business.name }}</h1>
    </div>
    <div class="mt-4 pa-4">
      <div class="text-subtitle-2 caption">Owner<template v-if="business.co_owner !== ''">s</template></div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-account
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-if="ownersFormatted !== ''">{{ ownersFormatted }}</v-list-item-title>
            <v-list-item-title v-else>N/A</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
      <div class="text-subtitle-2 caption">Personal details</div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-email-outline
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-if="business.email !== ''">{{ business.email }}</v-list-item-title>
            <v-list-item-title v-else>N/A</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-phone-outline
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-if="contactNumbersFormatted !==''">{{ contactNumbersFormatted }}</v-list-item-title>
            <v-list-item-title v-else>N/A</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
      <div v-if="business.private_address || business.industry || business.home_based || business.solo_business" class="text-subtitle-2 caption">Additional info</div>
      <v-list>
        <template v-if="business.industry">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-factory
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ industryFormatted }}</v-list-item-title>

            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
        </template>
        <template v-if="business.home_based">
          <v-list-item >
            <v-list-item-icon>
              <v-icon>
                mdi-check
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Home based</v-list-item-title>

            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
        </template>
        <template v-if="business.solo_business">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-check
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Solo business</v-list-item-title>

            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
        </template>
        <template v-if="business.private_address">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-check
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Private address</v-list-item-title>

            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
        </template>
      </v-list>

      <div class="text-subtitle-2 caption">Address</div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-map-marker-outline
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-if="businessAddress !== ''">
              {{ business.street }}
              <template v-if="business.city !== ''">{{ `, ${business.city}` }}</template>
              <template v-if="business.state !== ''">{{ `, ${business.state}` }}</template>
              <template v-if="business.country !== ''">{{ `, ${business.country}` }}</template>
              <template v-if="business.zip_code !== ''">{{ ` ${business.zip_code}` }}</template>
            </v-list-item-title>
            <v-list-item-title v-else>N/A</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
    </div>
    <DeleteBusiness
      title="Delete Business"
      :message="`Are you sure you want to delete ${business.name}?`"
      icon="delete"
      action="Delete"
      @submit="deleteItem"/>
    <v-btn
      class="add-btn"
      color="primary"
      large dark absolute bottom right
    >
      <v-icon class="mr-2">mdi-message-text</v-icon> New Contact
    </v-btn>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import DeleteBusiness from '@/components/ConfirmModal.vue';

  export default {
    name: 'ViewBusiness',
    components: {
      DeleteBusiness
    },
    data() {
      return {
        business: {
          id: '',
          name: '',
          owner: '',
          industry: '',
          home: '',
          office: '',
          email: '',
          contact_number: 0,
          streetaddress: '',
          state: '',
          city: '',
          county: '',
          zip_code: 0,
          home_based: null,
          solo_business: null,
          private_address: null
        },
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        overlay: true
      }
    },
    computed: {
      businessAddress() {
        return `${this.business.street}${this.business.city}${this.business.state}${this.business.country}${this.business.zip_code}`
      },
      industryFormatted() {
        return this.business.type ? `${this.business.industry } - ${this.business.type}` : this.business.industry;
      },
      contactNumbersFormatted() {
        return this.business.co_owner_contact_number ? `${this.business.owner_contact_number } / ${ this.business.co_owner_contact_number }` : this.business.owner_contact_number;
      },
      ownersFormatted() {
        return this.business.co_owner ? `${this.business.owner}, ${this.business.co_owner}` : this.business.owner;
      }
    },
    created() {
      this.getCurrentBusiness(Number(this.$route.params.id))
    },
    methods: {
      ...mapActions(['getBusiness', 'deleteBusiness']),
      getCurrentBusiness(id) {
        this.getBusiness({ id: id })
        .then((response) => {
        console.log('response', response)
          this.business = Object.assign({}, this.business, response.data )
          this.overlay = false
        }, (error) => {
          console.log('error', error)
          this.overlay = false
        });
      },
      deleteItem() {
        this.deleteBusiness({id: this.business.id})
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .v-btn.error {
    position: absolute;
    bottom: 16px;
    left: 16px;
  }
</style>
